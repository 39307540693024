import { useState, useContext } from "react";
import Moment from "moment";
import ModalContext from "../../../context/ModalContext";

export const OptionsItemContrato = ({ nextStep, prevStep, articulos }) => {
    const { select, data } = useContext(ModalContext);
    const [alerta, setAlerta] = useState("");

    const [fechadesde, setFechadesde] = useState(() => {
        if (data.Items.length === 0) {
            return Moment(data.FechaParte).format("YYYY-MM-DD HH:mm:ss");
        } else {
            if (data.Items[data.Items.length - 1]?.FechaCierre !== undefined) {
                return Moment(
                    data.Items[data.Items.length - 1]?.FechaCierre
                ).format("YYYY-MM-DD HH:mm:ss");
            }
        }
    });
    const [fechahasta, setFechahasta] = useState(
        Moment().startOf("day").add(1, "day").format("YYYY-MM-DD HH:mm:ss")
    );

    const itemSelected = articulos.find((articulo) => {
        if (articulo.id === parseInt(select)) {
            return articulo;
        }
    });

    const handleFechaHasta = ({ target }) => {
        const { value } = target;
        if (value <= fechadesde) {
            setAlerta("La fecha ingresada es menor que la fecha de inicio");
        } else {
            setAlerta("");
            setFechahasta(value);
        }
    };

    const handleValidation = () => {
        // console.log(itemSelected);

        var obj = [{ obj: itemSelected, inicio: fechadesde, fin: fechahasta }];
        nextStep(obj);
        const cantidadXItem = data.Items.map((item) => {
            if (item.Horas) {
            }
        });
        if (data.CalculaHorasHasta24XConceptos) {
        }
    };

    return (
        <>
            {alerta && (
                <div className="rounded-md bg-yellow-100 p-4 absolute bottom-2 right-80 z-20 ">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-yellow-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-yellow-800">
                                {alerta}
                            </p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    onClick={() => setAlerta("")}
                                    className="inline-flex rounded-md bg-yellow-100 p-1.5 text-yellow-600 hover:bg-yellow-200 focus:outline-none"
                                >
                                    <span className="sr-only">Close</span>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex items-center justify-between mt-3">
                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={prevStep}
                >
                    Atrás
                </button>

                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={handleValidation}
                >
                    Agregar
                </button>
            </div>

            <div className="bg-gray-200 py-5 px-3 my-5">
                <div className="inline-flex items-center">
                    <span className="text-sm">Código: </span>
                    <span className="text-xs font-semibold italic ml-3">
                        {itemSelected.Codigo}
                    </span>
                </div>
                <br />
                <div className="inline-flex items-center">
                    <span className="text-sm">Nombre item: </span>
                    <span className="text-xs font-semibold italic ml-3">
                        {" "}
                        {itemSelected.Nombre}
                    </span>
                </div>
            </div>

            {data.ContratoOperacion.CargaItemsXHora ? (
                <>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Fecha desde
                            </label>
                            <div className="mt-1">
                                <input
                                    type="datetime-local"
                                    name="fechadesde"
                                    id="fechadesde"
                                    value={fechadesde}
                                    onChange={(e) =>
                                        setFechadesde(e.target.value)
                                    }
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label
                                htmlFor="last-name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Fecha hasta
                            </label>
                            <div className="mt-1">
                                <input
                                    type="datetime-local"
                                    name="fechahasta"
                                    id="fechahasta"
                                    value={fechahasta}
                                    onChange={handleFechaHasta}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <input type="number" name="" id="" />
                </>
            )}
        </>
    );
};
