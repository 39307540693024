import * as Op from '../components/Operaciones/'
export const svgCambiar = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
    </svg>
);

export const tipo_operacion_key = {
    venta: 1,
    parteoperativo: 77,
    presupuestocliente: 11,
    ordencompracliente: 22,
    ordenpedidointerna: 21,
};

export const parseRoute = ($route) => {
    switch ($route) {
        case "ordencompracliente":
            return Op.OrdenCompraCliente;
        case "parteoperativo":
            return Op.ParteOperativo;
        case "venta":
            return Op.Venta;
        case "presupuestocliente":
            return Op.PresupuestoCliente;
        case "ordenpedidointernasectorizada":
            return Op.OrdenPedidoInternaSectorizada;
        case "ordenpedidointernadeposito":
            return Op.OrdenPedidoInternaDeposito;
        case "ordencompraproveedor":
            return Op.OrdenCompraProveedor;
        default:
            return null;
    }
};