import { useContext } from "react";
import UserContext from "../context/UserContext";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
    const { user } = useContext(UserContext);
    // const { pathname, search } = useLocation();

    // const lastPath = pathname + search;
    // localStorage.setItem("lastPath", lastPath);

    return user ? children : <Navigate to="/login" />;
};
