import { useContext, useEffect, useState } from "react";
import FilterContext from "../../context/FilterContext";

export const FilterByCheckboxGeneric = ({
    source,
    sourceIdField,
    sourceDescField,
    tipoFiltro,
    title,
    viewField,
    operacion
}) => {
    const { dispatchFilterReducer } = useContext(FilterContext);
    const [selectedValues, setSelectedValues] = useState([]);

    const handleChange = ({ target }) => {
        const { checked, id } = target;

        if (checked) {
            addIdToValues(id);
        } else {
            removeIdFromValues(id);
        }
    };

    useEffect(() => {
        if (selectedValues.length!=0) {

            const action = {
                type:'addOrUpdateFilter',
                payload:{
                    filterName:'byCheckbox_'+viewField, 
                    filterType:tipoFiltro , 
                    filterField:viewField, 
                    values:selectedValues,
                    operacion
                },
            };
    
            dispatchFilterReducer(action); 
        }
    }, [selectedValues]);


    function addIdToValues(id){
        setSelectedValues([...selectedValues, id]);
    };

    function removeIdFromValues(id){
        const tmp = selectedValues.filter(
            (item) => item != id
        );
        setSelectedValues(tmp);
    };

    return (
        <div className="mt-4">
            <label className="text-sm font-medium text-gray-900">
                Estado de la orden {title}
            </label>

            <fieldset className="mt-4">
                <legend className="sr-only">Estado operaciones</legend>
                <div className="space-y-4">
                    {source.map((s) => (
                        <div key={s.id} className="flex items-center">
                            <input
                                id={s[sourceIdField]}
                                name={s[sourceDescField]}
                                type="checkbox"
                                value={s[sourceDescField]}
                                onChange={handleChange}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 radiosButton"
                            />
                            <label
                                htmlFor={s[sourceIdField]}
                                className="ml-3 block text-sm font-medium text-gray-700"
                            >
                                {s[sourceDescField]}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </div>
    );
};
