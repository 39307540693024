import { useRef, useState, useContext } from "react";
import UserContext from "../../../context/UserContext";
import ModalContext from "../../../context/ModalContext";
import axios from "axios";

export const useSelectorCliente = (ruta, action, handleChange, onAddCliente) => {
    const { user, url, headers, workstationActive } = useContext(UserContext);
    const { data, select, setSelect } = useContext(ModalContext);

    const [open, setOpen] = useState(false);
    const [alerta, setAlerta] = useState("");
    const [busqueda, setBusqueda] = useState("");
    const [clientes, setClientes] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });
    const cancelButtonRef = useRef(null);
    const filterResult = clientes.filter((item) =>
        item.Filtered.toLowerCase().includes(busqueda.toLowerCase())
    );

    const getClientes = async () => {
        setOpen(true);
        setLoading(true);
        await axios
            .post(url + "cliente/readall", {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                RequiereContexto: true,
            })
            .then(function (response) {
                console.log(response);
                setLoading(false);

                setClientes(response.data.Data);
                setColumns(response.data.WebForms);
                setSelect([]);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const selectCliente = () => {
        if (select.length === 0) {
            setAlerta("Debe seleccionar un item al menos.");
        } else {
            setOpen(false);
            onAddCliente(select);
        }
    };

    const handleRequestCliente = async (param) => {
        await axios
            .post(url + ruta + action + param, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                [ruta]: data,
            })
            .then(function (response) {
                // console.log(response);
                setOpen(!open);
                handleChange(response.data);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    return {
        open,
        alerta,
        setOpen,
        loading,
        columns,
        setAlerta,
        clientes,
        cancelButtonRef,
        sortConfig,
        getClientes,
        selectCliente,
        setSortConfig,
        handleRequestCliente,
        busqueda,
        setBusqueda,
        filterResult
    };
};
