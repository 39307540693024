import moment from "moment/moment";
import { useState, useEffect, useContext } from "react";
import FilterContext from "../../context/FilterContext";

export const FilterByInputGeneric = ({
    title, 
    viewField,
    tipoFiltro
}) => {
    const { dispatchFilterReducer } = useContext(FilterContext);
    const [queryString, setQueryString] = useState(
        tipoFiltro === 'byDate' ? moment().format("YYYY-MM-DD") : ''
    );

    const handleOnChange = ({ target }) => {
        const { value } = target;

        if (value === "") {
            deleteSelection();
            return;
        }

        setQueryString(value);
    };

    const handleBlur = () => {
        
        if (queryString === "") {
            deleteSelection();
            return;
        } 
        
        const action = {
            type:'addOrUpdateFilter',
            payload:{filterName:'byInput_'+viewField, filterType:tipoFiltro, filterField:viewField, queryString: queryString},
        };

        dispatchFilterReducer(action); 
            
    };

    const deleteSelection = () => {
        setQueryString('');

        const action = {
            type:'deleteFilter',
            payload:{filterName:'byInput_'+viewField},
        };

        dispatchFilterReducer(action); 
    };

    return (
        <div className="mt-4">
            <label className="text-sm font-medium text-gray-900">
                {title}
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                        type={ tipoFiltro === 'byDate' ? "date" : "text"}
                        className="block w-full rounded-l-md sm:text-sm border-gray-300"
                        placeholder="Busque por cualquier dato..."
                        name="searchFilter"
                        autoComplete="off"
                        onChange={handleOnChange}
                        onBlur={handleBlur}
                        value={queryString}
                    />
                    {
                      (tipoFiltro !== 'byDate') ?
                            (<button
                                onClick={deleteSelection}
                                type="button"
                                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                </svg>
                            </button>) 
                            :(<></>)
                    }                    
                </div>
            </div>
        </div>
    );
};
