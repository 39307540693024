import { useState, useContext, useEffect } from "react";
import axios from 'axios'
import ModalContext from "../../../context/ModalContext";
import UserContext from "../../../context/UserContext";

import { Combobox } from "@headlessui/react";

export const OptionsItemCliente = ({ nextStep, prevStep, clientes}) => {
    const { select, data } = useContext(ModalContext);
    const { user, url, headers, workstationActive } = useContext(UserContext);
    const [alerta, setAlerta] = useState("");
    const [search, setSearch] = useState("")
    const [cuentas, setCuentas] = useState([]);
    const [selectCuenta, setSelectCuenta] = useState(null);
  

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    const filterData =
        search === ""
            ? cuentas
            : cuentas.filter((cuenta) => {
                return cuenta.Numero.toLowerCase().includes(search.toLowerCase());
            });

    const clienteSelected = clientes.find(
        (cliente) => cliente.id === parseInt(select)
    );

    const requestCuentaCliente = async() => {
        await axios.post(url + `cuentacorriente/readall/cliente/${select}`, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                RequiereContexto: true,
            })
            .then(function (response) {
                setCuentas(response.data.Data);
                // setSelect([]);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if(select){
            requestCuentaCliente();
        }
    }, [select])
    

    useEffect(() => {
        if (selectCuenta) {
            setAlerta("");
        }
    }, [select]);

    const handleValidation = () => {
        var validation = false;

        if (selectCuenta !==  null) {
            validation = true;
        }

        if (validation) {
            nextStep(`${select}/${selectCuenta}`);
        } else {
            setAlerta("Debe seleccionar una cuenta para avanzar");
        }
    };


    return (
        <>
            {alerta && (
                <div className="rounded-md bg-yellow-100 p-4 absolute bottom-2 right-80 z-20 ">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-yellow-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-yellow-800">
                                {alerta}
                            </p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    onClick={() => setAlerta("")}
                                    className="inline-flex rounded-md bg-yellow-100 p-1.5 text-yellow-600 hover:bg-yellow-200 focus:outline-none"
                                >
                                    <span className="sr-only">Close</span>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex items-center justify-between mt-3">
                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={prevStep}
                >
                    Atrás
                </button>

                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={handleValidation}
                >
                    Agregar
                </button>
            </div>

            <div className="bg-gray-200 py-5 px-3 my-5">
                <div className="inline-flex items-center">
                    <span className="text-sm">Razón social: </span>
                    <span className="text-xs font-semibold italic ml-3">
                        {clienteSelected.RazonSocial}
                    </span>
                </div>
                <br />
                <div className="inline-flex items-center">
                    <span className="text-sm">CUIT: </span>
                    <span className="text-xs font-semibold italic ml-3">
                        {" "}
                        {clienteSelected.CUIT}
                    </span>
                </div>
                <div>
                    <Combobox
                        as="div"
                        value={selectCuenta}
                        onChange={setSelectCuenta}
                    >
                        <Combobox.Label className="text-sm items-center">
                            Cuenta corriente: 
                        </Combobox.Label>
                        <div className="relative mt-2">
                            <Combobox.Input
                                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(event) =>
                                    setSearch(event.target.value)
                                }
                                displayValue={(cuenta) => cuenta ? 'Cuenta Nº: '+cuenta : 'Seleccione una cuenta'}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </Combobox.Button>

                            {filterData.length > 0 && (
                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {filterData.map((cuenta) => (
                                        <Combobox.Option
                                            key={cuenta.id}
                                            value={cuenta.Numero}
                                            className={({ active }) =>
                                                classNames(
                                                    "relative cursor-default select-none py-2 pl-3 pr-9",
                                                    active
                                                        ? "bg-indigo-600 text-white"
                                                        : "text-gray-900"
                                                )
                                            }
                                        >
                                            {({ active, selected }) => (
                                                <>
                                                    <div className="flex">
                                                        <span
                                                            className={classNames(
                                                                "truncate",
                                                                selected &&
                                                                    "font-semibold"
                                                            )}
                                                        >
                                                            Cuenta Nº: {cuenta.Numero}
                                                        </span>
                                                        {/* <span
                                                            className={classNames(
                                                                "ml-2 truncate text-gray-500",
                                                                active
                                                                    ? "text-indigo-200"
                                                                    : "text-gray-500"
                                                            )}
                                                        >
                                                            {cuenta.Numero}
                                                        </span> */}
                                                    </div>

                                                    {selected && (
                                                        <span
                                                            className={classNames(
                                                                "absolute inset-y-0 right-0 flex items-center pr-4",
                                                                active
                                                                    ? "text-white"
                                                                    : "text-indigo-600"
                                                            )}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-5 w-5"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M5 13l4 4L19 7"
                                                                />
                                                            </svg>
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            )}
                        </div>
                    </Combobox>
                </div>
            </div>
        </>
    );
};
