import { useState, Fragment, useRef, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import ModalContext from '../../../context/ModalContext';

export const EquivalenciaMoneda = ( {nextStep, monedas, prevStep } ) => {
    const { select, data } = useContext(ModalContext);
    const currency = monedas.find(item => item.id == select);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [equivalenciaInput, setEquivalenciaInput] = useState(currency.EquivalenciaPesos);
    const inputRef = useRef(null);

    

    const handleCurrencyChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedCurrency(selectedValue);
    };

    const handleChangeCurrency = (event) => {
        event.stopPropagation(); // Evitar que el evento se propague al menú
        const { value } = event.target;
        setEquivalenciaInput(value);
    };

    const handleValidation = () => {
        let validation = false;
        nextStep(`${select}/${equivalenciaInput}`);
    }

    
    return (
        <>
            <div className="flex items-center justify-between mt-3">
                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={prevStep}
                >
                    Atrás
                </button>

                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={handleValidation}
                >
                    Agregar
                </button>
            </div>
            <div className="bg-gray-200 py-5 px-3 my-5">
                <div className="inline-flex items-center">
                    <span className="text-sm">Nombre: </span>
                    <span className="text-xs font-semibold italic ml-3">
                        {currency.Nombre}
                    </span>
                </div>
                <br />
                <div className="inline-flex items-center">
                    <span className="text-sm">Simbolo: </span>
                    <span className="text-xs font-semibold italic ml-3">
                        {currency.Simbolo}
                    </span>
                </div>
            </div>
            {/* <div className="lg:col-start-3 lg:row-end-1 mt-8">
                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                    <dl className="flex flex-wrap">
                        <div className="flex-auto pl-6 pt-6">
                            <dt className="text-sm font-semibold leading-6 text-gray-900">
                                Divisa elegida
                            </dt>
                            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                                {currency.Nombre}
                            </dd>
                        </div>
                        <div className="flex-none self-end px-6 pt-4">
                            <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                {currency.Simbolo} {currency.EquivalenciaPesos}
                            </dd>
                        </div>
                        <Menu as="div" className="relative">
                            <div>
                                <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                    <span className="sr-only">
                                        Open options
                                    </span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M5 19h1.4l8.625-8.625l-1.4-1.4L5 17.6V19ZM19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM4 21q-.425 0-.713-.288T3 20v-2.825q0-.2.075-.388t.225-.337l10.3-10.3l4.25 4.25l-10.3 10.3q-.15.15-.337.225T6.825 21H4ZM14.325 9.675l-.7-.7l1.4 1.4l-.7-.7Z"
                                        ></path>
                                    </svg>
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                                afterEnter={() => inputRef.current.focus()}
                            >
                                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                    <dd className="text-sm mt-2 font-medium leading-6 text-gray-900">
                                        Equivalencia a Modificar
                                    </dd>
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        name="valorMoneda"
                                        id="valorMoneda"
                                        placeholder={currency.EquivalenciaPesos}
                                        value={equivalenciaInput}
                                        onChange={handleChangeCurrency}
                                        className="block w-auto rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </Transition>
                        </Menu>
                    </dl>
                    <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                        <button
                            className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                            onClick={() => peticion(equivalenciaInput)}
                        >
                            Aceptar
                        </button>
                    </div>
                </div>
            </div> */}
            {/* <div>
            <div className="relative mt-2 flex items-center">
                <input
                    type="text"
                    name="valorMoneda"
                    id="valorMoneda"
                    placeholder={currency.EquivalenciaPesos}
                    value={equivalenciaInput}
                    onChange={handleChangeCurrency}
                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                    <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                        <button
                            className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                            onClick={() => peticion(equivalenciaInput)}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m19.8 12.925l-15.4 6.5q-.5.2-.95-.088T3 18.5v-13q0-.55.45-.838t.95-.087l15.4 6.5q.625.275.625.925t-.625.925ZM5 17l11.85-5L5 7v3.5l6 1.5l-6 1.5V17Zm0 0V7v10Z"></path></svg>
                        </button>
                    </kbd>
                </div>
            </div>
        </div> */}
        </>
    );
}