import { useContext } from "react";
import { useState } from "react";
import ModalContext from "../../../../context/ModalContext";

export const FourthComponent = ({ nextStep, prevStep }) => {
    const { data, setData } = useContext(ModalContext);

    const [textNotasPublicas, setTextNotasPublicas] = useState(
        data.Operacion.Notas
    );
    const [textNotasPrivadas, setTextNotasPrivadas] = useState(
        data.NotasPrivadas
    );

    const handleNotasPrivadas = ({ target }) => {
        const { name, value } = target;
        setTextNotasPrivadas(value);
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleNotasPublicas = ({ target }) => {
        const { name, value } = target;
        setTextNotasPublicas(value);
        setData({
            ...data,
            Operacion: {
                ...data.Operacion,
                [name]: value,
            },
        });
    };

    const notasPublicas = () => {
        return (
            <textarea
                rows={6}
                cols={12}
                name="Notas"
                onChange={handleNotasPublicas}
                className="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                value={textNotasPublicas}
            />
        );
    };
    const notasPrivadas = () => {
        return (
            <textarea
                rows={6}
                cols={12}
                name="NotasPrivadas"
                id="NotasPrivadas"
                onChange={handleNotasPrivadas}
                className="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                value={textNotasPrivadas}
            />
        );
    };

    const [currentTab, setCurrentTab] = useState("1");

    const tabs = [
        { id: 1, tabTitle: "tab 1", title: "Notas", content: notasPublicas },
        {
            id: 2,
            tabTitle: "tab 2",
            title: "Notas privadas",
            content: notasPrivadas,
        },
    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    };

    return (
        <>
            <div className="flex items-center justify-between mt-3 mx-10">
                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={prevStep}
                >
                    Atrás
                </button>

                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={nextStep}
                >
                    Guardar
                </button>
            </div>
            <div className="hidden sm:block pt-5">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                        {tabs.map((tab, i) => (
                            <button
                                key={i}
                                id={tab.id}
                                className={`w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                                    currentTab === `${tab.id}`
                                        ? "border-indigo-500 text-indigo-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                }`}
                                onClick={handleTabClick}
                            >
                                {tab.title}
                            </button>
                        ))}
                    </nav>
                </div>

                <div className="content">
                    {tabs.map((tab, i) => (
                        <div key={i}>
                            {currentTab === `${tab.id}` && (
                                <div>
                                    <p>{tab.content()}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
