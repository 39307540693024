import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const ColumnaDistribucion = ({columna}) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: columna.WebFormDefaultKey });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    // console.log(style);
    return (
        <div
            className="relative flex items-start py-3 hover:bg-gray-100"
            style={style}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
        >
            <h1
                className={`text-sm ${
                    columna.Visible === false
                        ? "text-gray-400 italic"
                        : "text-gray-800"
                }`}
            >
                {columna.FormFieldTitle}
            </h1>
        </div>
    );
};
