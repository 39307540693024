//react
import axios from "axios";
import { useState, useEffect } from "react";

//components

import { SelectorArticulo } from "../../../Selector/SelectorByListaPrecio/SelectorArticulo";

//assets
import { useContext } from "react";
import UserContext from "../../../../context/UserContext";
import ModalContext from "../../../../context/ModalContext";
import { ArticulosItem } from "./ArticulosItem";
import { useActions } from "../../../../permissions/useActions";


export const SecondComponent = ({ nextStep, prevStep }) => {
    const { url, headers, user, workstationActive } = useContext(UserContext);
    const { data, setData, acciones } = useContext(ModalContext);
    const { isAllowed } = useActions(acciones);
    var cantidad = 1;
    const [items, setItems] = useState([]);


    var listaPrecioKey = data.Operacion.ListaPrecios == null ? 1 : data.Operacion.ListaPrecios.ListaPreciosKey ;

    const handleAddArticle = async (e) => {
        await axios
            .post(url + `ordencompracliente/add_article/${e}/${cantidad}`, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                ordencompracliente: data,
            })
            .then(function (response) {
                setData(response.data);
                setItems(response.data.Items);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        setItems(data.Items);
    }, [data]);

    return (
        <>
            <div className="flex items-center justify-between mt-3 mx-10">
                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={prevStep}
                >
                    Atrás
                </button>

                <button
                    className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                    onClick={nextStep}
                >
                    Siguiente
                </button>
            </div>
            <div className="mt-3">
                {/* {isAllowed(
                    "OPERACION_ORDEN_COMPRA_CLIENTE_AGREGAR_ARTICULOGENERICO"
                ) && (
                    )} */}
                <SelectorArticulo
                    onAddArticle={handleAddArticle}
                    listaPrecioKey={listaPrecioKey}
                />
            </div>
            <div className="mt-4 flex flex-col border h-60">
                <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-300">
                                    <tr className="border">
                                        <th
                                            scope="col"
                                            className="py-1 pl-4 pr-3 w-0 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            Depo
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-1 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Código
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-1 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Artículo
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-1 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Cantidad
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-1 px-3 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Unitario
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-1 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Dto.
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-1 px-3 w-0 text-left text-sm font-semibold text-gray-900 sm:pr-3"
                                        >
                                            P. Total
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative w-0 py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white ">
                                    {items.map((item, itemIdx) => (
                                        <ArticulosItem
                                            key={itemIdx}
                                            item={item}
                                            itemIdx={itemIdx}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
