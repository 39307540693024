export const ColumnaVisibilidad = ({ columna, handleOnChange })  => {
    const handleChange = (e) => {
        handleOnChange(e);
    };

    return (
        <div className="inline-flex items-center justify-between w-full py-3 hover:bg-gray-100">
            <label
                htmlFor={`columna-${columna.FormFieldTitle}`}
                className="text-sm text-gray-700 "
            >
                {columna.FormFieldTitle}
            </label>
            <input
                type="checkbox"
                value={columna.WebFormDefaultKey}
                checked={columna.Visible}
                onChange={handleChange}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
        </div>
    );
}
