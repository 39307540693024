import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const ColumnaOrden = ({ columnas, columnasDefault, onHandleToOrder }) => {
    const [open, setOpen] = useState(false);
    const [col, setCol] = useState([]);
    const [colDefault, setColDefault] = useState([]);
    const [columnaSeleccionada, setColumnaSeleccionada] = useState(null);
    const [colDefaultSeleccionada, setColDefaultSeleccionada] = useState(null);

    useEffect(() => {
        setColDefault(columnasDefault);
        setCol(columnas);
    }, [columnas, columnasDefault]);

    const columnasDefaultParse = colDefault.map((cd) => {
        const coincidencia = col.find(
            (columna) => columna.FormFieldName === cd.fieldname
        );

        if (coincidencia) {
            return {
                ...cd,
                FormFieldTitle: coincidencia.FormFieldTitle,
            };
        }

        return colDefault;
    });

    const columnasParse = col.filter((c) => {
        return !colDefault.some((cd) => cd.fieldname === c.FormFieldName);
    });

    const agregarColumna = (columna) => {
        let newCol = {
            fieldname: columna.FormFieldName,
            fieldtype: "asc",
        };
        setColDefault([...colDefault, newCol]);
        setColumnaSeleccionada(null);
    };

    const quitarColumna = (columna) => {
        if (columna) {
            const newColDefault = colDefault.filter(
                (cd) => cd.fieldname !== columna.fieldname
            );
            setColDefault(newColDefault);
        }
        setColDefaultSeleccionada(null);
    };

    const subirColumna = (index) => {
        if (index >= 0 && index < colDefault.length) {
            const removedItem = colDefault.splice(index, 1);

            colDefault.splice(index - 1, 0, removedItem[0]);
        }
    };

    const bajarColumna = (index) => {
        if (index >= 0 && index < colDefault.length - 1) {
            const removedItem = colDefault.splice(index, 1);

            colDefault.splice(index + 1, 0, removedItem[0]);
        }
    };

    const cambiarDireccionColumna = (columna) => {
        const colChange = colDefault.find(
            (cd) => cd.fieldname === columna.fieldname
        );
        if (colChange) {
            colChange.fieldtype = colChange.fieldtype === 'asc' ? 'desc' : 'asc'
        }
        setColDefaultSeleccionada(null);
    };

    const guardarColumnas = () => {
        // if()
        setOpen(!open);
        onHandleToOrder(colDefault);

    };

    // useEffect(() => {
    //   if(columnaSeleccionada){
    //     setColumnaSeleccionada(null)
    //   }
    // }, [colDefaultSeleccionada])

    // useEffect(() => {
    //   if(colDefaultSeleccionada){
    //     setColDefaultSeleccionada(null)
    //   }
    // }, [columnaSeleccionada])

    // useEffect(() => {

    //   return () => {
    //     second
    //   }
    // }, [third])

    // console.log(colDefaultSeleccionada);

    return (
        <div className="">
            <button
                onClick={() => setOpen(!open)}
                className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Modificar orden columnas
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                                    <div className="sm:flex sm:items-start space-x-5">
                                        <div className="h-96 bg-gray-50 w-1/2 p-3 rounded-md border">
                                            <div className="w-full overflow-y-auto h-full ">
                                                <p className="py-2 text-black font-bold">
                                                    Columnas disponibles
                                                </p>
                                                {columnasParse.map(
                                                    (columna, index) => (
                                                        <button
                                                            key={index}
                                                            className={`w-full text-left  hover:bg-gray-100 border-b border-gray-300 py-1.5 ${
                                                                columnaSeleccionada &&
                                                                columnaSeleccionada.WebFormDefaultKey ===
                                                                    columna.WebFormDefaultKey
                                                                    ? "bg-gray-300 hover:bg-gray-200"
                                                                    : ""
                                                            }`}
                                                            onClick={() =>
                                                                setColumnaSeleccionada(
                                                                    columna
                                                                )
                                                            }
                                                        >
                                                            {
                                                                columna.FormFieldTitle
                                                            }
                                                        </button>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="h-96  w-1/12">
                                            <div className="h-full flex flex-col items-center justify-center space-y-5">
                                                <button
                                                    className="h-10 w-10 bg-gray-50 rounded-md border"
                                                    onClick={() =>
                                                        agregarColumna(
                                                            columnaSeleccionada
                                                        )
                                                    }
                                                >{`>`}</button>
                                                <button
                                                    className="h-10 w-10 bg-gray-50 rounded-md border"
                                                    onClick={() =>
                                                        quitarColumna(
                                                            colDefaultSeleccionada
                                                        )
                                                    }
                                                >{`<`}</button>
                                            </div>
                                        </div>

                                        <div className="h-96 bg-gray-50 w-1/2 p-3 rounded-md border">
                                            <div className="w-full h-full overflow-y-auto flex-col ">
                                                <p className="py-2 text-black font-bold">
                                                    Columnas seleccionadas
                                                </p>
                                                {/* <div className="w-full flex bg-gray-600">
                                                    <p className="w-10">
                                                        Ajuste
                                                    </p>
                                                    <p className="w-1/3 text-left">
                                                        Nombre
                                                    </p>
                                                    <p className="w-1/3">
                                                        Dirección
                                                    </p>
                                                </div> */}
                                                {columnasDefaultParse.map(
                                                    (cd, index) => (
                                                        <div
                                                            onClick={() =>
                                                                setColDefaultSeleccionada(
                                                                    cd
                                                                )
                                                            }
                                                            on={() =>
                                                                setColDefaultSeleccionada(
                                                                    null
                                                                )
                                                            }
                                                            key={index}
                                                            className={`w-full inline-flex hover:bg-gray-100 cursor-pointer items-center justify-between py-0.5 border-b border-gray-300 ${
                                                                colDefaultSeleccionada &&
                                                                colDefaultSeleccionada.fieldname ===
                                                                    cd.fieldname
                                                                    ? "bg-gray-300 hover:bg-gray-200"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <div className="inline-flex items-center space-x-3">
                                                                <div className="flex-col space-y-0.5">
                                                                    <button
                                                                        onClick={() =>
                                                                            subirColumna(
                                                                                index
                                                                            )
                                                                        }
                                                                        className={`bg-gray-50 rounded-md border hover:bg-indigo-300 flex ${
                                                                            index ===
                                                                            columnasDefaultParse.lenght -
                                                                                1
                                                                                ? "cursor-not-allowed"
                                                                                : "cursor-pointer"
                                                                        }`}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                        onClick={() =>
                                                                            bajarColumna(
                                                                                index
                                                                            )
                                                                        }
                                                                        className={`bg-gray-50 rounded-md border hover:bg-indigo-300 flex`}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <p className="text-sm">
                                                                    {
                                                                        cd.FormFieldTitle
                                                                    }
                                                                </p>
                                                            </div>
                                                            <button
                                                                onClick={() =>
                                                                    cambiarDireccionColumna(
                                                                        cd
                                                                    )
                                                                }
                                                                className={`rounded-md border ${
                                                                    cd.fieldtype ===
                                                                    "asc"
                                                                        ? "rotate-180"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M19 9l-7 7-7-7"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                            onClick={guardarColumnas}
                                        >
                                            Guardar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};
