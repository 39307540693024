import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import UserContext from "../../../../context/UserContext";
import ModalContext from "../../../../context/ModalContext";

export const ModalOptionsHeaderOcc = ({
    action,
    actionText,
    items,
    handleAction,
}) => {
    const [open, setOpen] = useState(false);

    const { url, headers } = useContext(UserContext);
    const { data, setData, select, setSelect } = useContext(ModalContext);
    const cancelButtonRef = useRef(null);
    const [error, setError] = useState("");

    const handleChange = (e) => {
        e.preventDefault();
        switch (action) {
            case "del_article":
                // deleteArticle();
                break;
        }
    };

    const cancelButton = () => {
        setOpen(false);
        handleAction(false);
    };

    return (
        <>
            <button
                className="w-full font-medium block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                onClick={() => setOpen(!open)}
            >
                {actionText}
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto w-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-auto sm:p-6">
                                    <form onSubmit={handleChange}>
                                        {
                                            {
                                                del_article: (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-red-600"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                {actionText}
                                                            </Dialog.Title>
                                                            <div className="mt-2">
                                                                <p className="text-sm text-gray-500">
                                                                    ¿Está seguro
                                                                    que quiere
                                                                    eliminar el
                                                                    artículo "
                                                                    <br />
                                                                    Esta acción
                                                                    no se puede
                                                                    deshacer.
                                                                    Deberá
                                                                    cargar
                                                                    nuevamente
                                                                    el artículo.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                            }[action]
                                        }
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                type="submit"
                                                className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm ${
                                                    action === "del_article"
                                                        ? " bg-red-600 hover:bg-red-700"
                                                        : "bg-blue-600 hover:bg-blue-800"
                                                }`}
                                            >
                                                {action === "del_article"
                                                    ? "Eliminar"
                                                    : "Aceptar"}
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                                                onClick={cancelButton}
                                                ref={cancelButtonRef}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
