import { useState, useContext } from "react";
import { useActions } from "../../../../permissions/useActions";
import ModalContext from "../../../../context/ModalContext";
import ModalMenuOption from "./ModalMenuOption";

export const ArticulosItem = ({ item, itemIdx }) => {
    const { data, setData } = useContext(ModalContext);
    const [menuOptions, setMenuOptions] = useState(false);
    const { isAllowed } = useActions();

    const [isEditing, setIsEditing] = useState(false);
    const [newValue, setNewValue] = useState(item.Item.Nombre);
    const [updatedObject, setUpdatedObject] = useState(item);

    const handleDescription = ({ target }) => {
        const { value } = target;
        setNewValue(value);
        setUpdatedObject({
            ...updatedObject,
            Item: {
                ...updatedObject.Item,
                Item: {
                    ...updatedObject.Item,
                    Nombre: value,
                },
            },
        });
    };

    const confirmNewDescription = () => {
        setIsEditing(false);
        const updateData = {
            ...data,
            Items: data.Items.map((item, index) =>
                index === itemIdx ? updatedObject : { ...item }
            ),
        };
        setData(updateData);
    };

    const cancelNewDescription = () => {
        setIsEditing(!isEditing);
        setNewValue(item.Item.Nombre);
    };

    return (
        <tr
            key={itemIdx}
            className={`border ${
                itemIdx % 2 === 0 ? undefined : "bg-gray-100"
            }`}
        >
            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {itemIdx}
            </td>
            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                {item.Item.Codigo}
            </td>
            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 truncate">
                <div className="inline-flex items-center w-full">
                    {isEditing ? (
                        <div className="w-11/12 focus:outline-none">
                            <input
                                type="text"
                                name="Nombre"
                                id="Nombre"
                                className="block -my-1 w-full rounded-lg bg-gray-50 sm:text-sm"
                                onChange={handleDescription}
                                value={newValue}
                                autoComplete="off"
                            />
                        </div>
                    ) : (
                        newValue
                    )}
                    {isAllowed(
                        "OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_ITEM"
                    ) && (
                        <div className="inline-flex items-center ml-3">
                            {isEditing ? (
                                <div className="inline-flex items-center space-x-1">
                                    <button onClick={confirmNewDescription}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    </button>
                                    <button onClick={cancelNewDescription}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={() => setIsEditing(!isEditing)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 ml-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                        />
                                    </svg>
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                {item.Cantidad}
            </td>
            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                {parseFloat(item.PrecioUnitarioNG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                {item.PorcentajeDescuento}
            </td>
            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                {parseFloat(item.PrecioTotalNG).toFixed(2)}
            </td>
            <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 inline-flex items-center">
                <div>
                    <button onClick={() => setMenuOptions(!menuOptions)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                            />
                        </svg>
                    </button>
                    {menuOptions && (
                        <div
                            className={`absolute right-0 z-10 w-66 origin-top-right rounded-md bg-gray-100 border shadow-lg`}
                        >
                            <ModalMenuOption
                                action="del_article"
                                actionText={"Eliminar artículo"}
                                indexItem={itemIdx}
                                handleAction={setMenuOptions}
                            />
                            <ModalMenuOption
                                action="change_amount_article"
                                actionText={"Modificar la cantidad del item"}
                                indexItem={itemIdx}
                                handleAction={setMenuOptions}
                            />
                            <ModalMenuOption
                                action="change_article_price"
                                actionText={"Modificar el precio del item"}
                                indexItem={itemIdx}
                                handleAction={setMenuOptions}
                            />
                            <ModalMenuOption
                                action="change_article_discount_percentage"
                                actionText={"Modificar descuento"}
                                indexItem={itemIdx}
                                handleAction={setMenuOptions}
                            />
                            {isAllowed(
                                "OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_ITEMDEPOSITOENTREGA"
                            ) && (
                                <ModalMenuOption
                                    action="change_article_delivery_deposit"
                                    actionText={
                                        "Modifica el depósito de entrega"
                                    }
                                    indexItem={itemIdx}
                                    handleAction={setMenuOptions}
                                />
                            )}
                        </div>
                    )}
                </div>
            </td>
        </tr>
    );
};
