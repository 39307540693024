import { useContext } from "react";
import Moment from "moment";
import axios from 'axios'
import Selector from "../../../Selector/Selector";
import {
    SelectorGenerico,
    SelectorCliente,
    SelectorByMoneda,
} from "../../../Selector";
import ModalContext from "../../../../context/ModalContext";
import UserContext from "../../../../context/UserContext";
import { useActions } from "../../../../permissions/useActions";
import AlertContext from "../../../../context/AlertContext";

export const FirstComponent = ({ruta, nextStep}) => {
    const { data, setData } = useContext(ModalContext);
    const { url, user, workstationActive } = useContext(UserContext);
    const { isAllowed } = useActions();
    const { setStatusCode, setResponseText } = useContext(AlertContext);

    const handleChange = (newData) => {
        setData(newData);
    }

    const handleValidation = () => {
        
        if(data.Nombre === "" ){
            setStatusCode(406);
            setResponseText("Debe seleccionar un cliente para continuar.");
            setTimeout(() => {
                setResponseText("");
            }, 3000);
        }else{
            nextStep()
        }
    }

    const requestFinalConsumer = async() => {
        await axios.post(url + ruta + "/to_assign_final_consumer", {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                [ruta] : data,
            })
            .then(function (res) {
                // console.log(res);
                setData(res.data);
            })
            .catch(function (err) {
                console.log(err.response);
            });
    }

    return (
        <>
            <div className="items-center mt-4 mr-10">
                <div className="flex justify-end w-full">
                    <button
                        className={` 
                            bg-indigo-600
                            text-white
                            hover:bg-indigo-900
                            px-2
                            py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer
                            `}
                        onClick={handleValidation}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-2 max-h-60 h-60 ml-32">
                <div className="h-48 pt-4 space-y-4">
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="first-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Cliente:
                        </label>
                        <div className="ml-4">
                            <SelectorCliente
                                textButton={`${
                                    data.Nombre === ""
                                        ? "Sin seleccion"
                                        : data.Nombre &&
                                          JSON.stringify(data.Nombre)
                                }`}
                                ruta={ruta}
                                action={"/to_assign_client/"}
                                data={data}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    {data.Nombre !== "Consumidor final" && (
                        <div className="inline-flex items-center w-full">
                            <p className="text-xs text-left mr-2">{`Consumidor final --> `}</p>
                            <button
                                className="text-xs text-left text-blue-500 hover:underline"
                                onClick={requestFinalConsumer}
                            >
                                {` Click aqui`}
                            </button>
                        </div>
                    )}
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="email"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Condicion Pago:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"condicionpago"}
                                        view={"single"}
                                        title={"Seleccione condición pago"}
                                        id={
                                            data.CondicionPago !== null &&
                                            data.CondicionPago &&
                                            data.CondicionPago.CondicionPagoKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.CondicionPago === null
                                        ? "Sin seleccion"
                                        : data.CondicionPago &&
                                          JSON.stringify(
                                              data.CondicionPago.Nombre
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_payment_condition/"}
                            />
                        </div>
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="email"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Vendedor:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"vendedor"}
                                        view={"single"}
                                        title={"Seleccione un vendedor"}
                                        id={
                                            data.Vendedor !== null &&
                                            data.Vendedor &&
                                            data.Vendedor.VendedorKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Vendedor === null
                                        ? "Sin seleccion"
                                        : data.Vendedor &&
                                          JSON.stringify(
                                              data.Vendedor.RazonSocial
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_applicant/"}
                            />
                        </div>
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="email"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Tipo de entrega:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"tipoentrega"}
                                        view={"single"}
                                        title={"Seleccione un tipo de entrega"}
                                        id={
                                            data.TipoEntrega !== null &&
                                            data.TipoEntrega &&
                                            data.TipoEntrega.TipoEntregaKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.TipoEntrega === null
                                        ? "Sin seleccion"
                                        : data.TipoEntrega &&
                                          JSON.stringify(
                                              data.TipoEntrega.Nombre
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_applicant/"}
                            />
                        </div>
                    </div>
                </div>
                <div className="h-48 pt-4 space-y-4">
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="FechaPedido"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha:
                        </label>
                        <input
                            type="date"
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                            name="FechaPedido"
                            value={Moment(data.FechaPedido).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="FechaVencimiento"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha vto.:
                        </label>
                        <input
                            type="date"
                            name="FechaVencimiento"
                            value={Moment(data.FechaVencimiento).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                        />
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="last-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Moneda:
                        </label>
                        <div className="ml-4">
                            <SelectorByMoneda
                                textButton={`${
                                    data.Operacion.Moneda === null
                                        ? "Sin seleccion"
                                        : data.Operacion.Moneda &&
                                          JSON.stringify(
                                              data.Operacion.Moneda.Nombre
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_currency/"}
                                onHandleChange={handleChange}
                            />
                            {/* <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"vendedor"}
                                        view={"single"}
                                        title={"Seleccione empleado designado"}
                                        id={
                                            data.Empleado !== null &&
                                            data.Empleado &&
                                            data.Empleado.VendedorKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Empleado === null
                                        ? "Sin seleccion"
                                        : data.Empleado &&
                                          JSON.stringify(
                                              data.Empleado.RazonSocial
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_applicant/"}
                            /> */}
                        </div>
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="email"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Lista de precio:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"listaprecios"}
                                        view={"single"}
                                        title={
                                            "Seleccione una lista de precios"
                                        }
                                        id={
                                            data.Operacion.ListaPrecios !==
                                                null &&
                                            data.Operacion.ListaPrecios &&
                                            data.Operacion.ListaPrecios
                                                .ListaPreciosKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Operacion.ListaPrecios === null
                                        ? "Sin seleccion"
                                        : data.Operacion.ListaPrecios &&
                                          JSON.stringify(
                                              data.Operacion.ListaPrecios.Nombre
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_price_list/"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
