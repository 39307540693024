import { useContext } from "react";
import Moment from "moment";
import Selector from "../../../Selector/Selector";
import { SelectorGenerico } from "../../../Selector";
import ModalContext from "../../../../context/ModalContext";
import { useActions } from "../../../../permissions/useActions";

export const FirstComponent = ({ ruta, nextStep, tipo }) => {
    const { data, setData, acciones } = useContext(ModalContext);
    const { isAllowed } = useActions(acciones);
    const handleChange = ({ target }) => {
        const { name, value, type } = target;
        if (type == "date") {
            setData({
                ...data,
                [name]: Moment(value).format("YYYY-MM-DDTHH:mm:ss"),
            });
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
    };

    return (
        <>
            <div className="items-center mt-4">
                <div className="flex justify-end w-full">
                    <button
                        className={` 
                            bg-indigo-600
                            text-white
                            hover:bg-indigo-900
                            px-2
                            py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer
                            `}
                        onClick={nextStep}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 ml-16">
                <div className="pt-8 pl-8 space-y-6">
                    {tipo == "cliente" ? (
                        <div className="w-full inline-flex items-center">
                            <label
                                htmlFor="first-name"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Bien de uso:
                            </label>
                            <div className="ml-4">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"bienuso"}
                                            view={"single"}
                                            title={"Seleccione un bien de uso"}
                                            id={
                                                data.Cliente !== null &&
                                                data.Cliente &&
                                                data.Cliente.ClienteKey
                                            }
                                        />
                                    }
                                    textButton={`${
                                        data.Cliente === null
                                            ? "Sin seleccion"
                                            : data.Cliente &&
                                              JSON.stringify(
                                                  data.Cliente.RazonSocial
                                              )
                                    }`}
                                    ruta={ruta}
                                    action={"/to_assign_client/"}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="w-full inline-flex items-center">
                            <label
                                htmlFor="first-name"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Proveedor:
                            </label>
                            <div className="ml-4">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"proveedor"}
                                            view={"single"}
                                            title={"Seleccione proveedor"}
                                            id={
                                                data.Proveedor !== null &&
                                                data.Proveedor &&
                                                data.Proveedor.ProveedorKey
                                            }
                                        />
                                    }
                                    textButton={`${
                                        data.Proveedor === null
                                            ? "Sin seleccion"
                                            : data.Proveedor &&
                                              JSON.stringify(
                                                  data.Proveedor.RazonSocial
                                              )
                                    }`}
                                    ruta={ruta}
                                    action={"/to_assign_supplier/"}
                                />
                            </div>
                        </div>
                    )}
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="last-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Empleado:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"vendedor"}
                                        view={"single"}
                                        title={"Seleccione vendedor"}
                                        id={
                                            data.Vendedor !== null &&
                                            data.Vendedor &&
                                            data.Vendedor.VendedorKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Vendedor === null
                                        ? "Sin seleccion"
                                        : data.Vendedor &&
                                          JSON.stringify(
                                              data.Vendedor.RazonSocial
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_applicant/"}
                            />
                        </div>
                    </div>
                    {/* {isAllowed(
                        "OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_CONDICIONPAGO"
                    ) && (
                    )} */}
                        <div className="w-full inline-flex items-center">
                            <label
                                htmlFor="email"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Condicion Pago:
                            </label>
                            <div className="ml-4">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"condicionpago"}
                                            view={"single"}
                                            title={"Seleccione condición pago"}
                                            id={
                                                data.CondicionPago !== null &&
                                                data.CondicionPago &&
                                                data.CondicionPago
                                                    .CondicionPagoKey
                                            }
                                        />
                                    }
                                    textButton={`${
                                        data.CondicionPago === null
                                            ? "Sin seleccion"
                                            : data.CondicionPago &&
                                              JSON.stringify(
                                                  data.CondicionPago.Nombre
                                              )
                                    }`}
                                    ruta={ruta}
                                    action={"/to_assign_applicant/"}
                                />
                            </div>
                        </div>
                    {isAllowed(
                        "OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_LISTAPRECIOS"
                    ) && (
                        <div className="w-full inline-flex items-center">
                            <label
                                htmlFor="email"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Lista precio:
                            </label>
                            <div className="ml-4">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"listaprecio"}
                                            view={"single"}
                                            title={"Seleccione lista precio"}
                                            id={
                                                data.Operacion.ListaPrecios !==
                                                    null &&
                                                data.Operacion.ListaPrecios
                                                    .ListaPreciosKey &&
                                                data.Operacion.ListaPrecios
                                                    .ListaPreciosKey
                                            }
                                        />
                                    }
                                    textButton={`${
                                        data.Operacion.ListaPrecios === null
                                            ? "Sin seleccion"
                                            : data.Operacion.ListaPrecios &&
                                              JSON.stringify(
                                                  data.Operacion.ListaPrecios
                                                      .Nombre
                                              )
                                    }`}
                                    ruta={ruta}
                                    action={"/to_assign_applicant/"}
                                />
                            </div>
                        </div>
                    )}
                    <div className="w-full inline-flex items-center pb-1">
                        <label
                            htmlFor="country"
                            className="block text-xs  font-semibold text-gray-900 mr-2"
                        >
                            Prioridad:
                        </label>
                        <div>
                            <select
                                className="block w-full rounded-md border-gray-300 shadow-sm sm:text-xs h-8"
                                name="Prioridad"
                                onChange={handleChange}
                                value={data.Prioridad}
                            >
                                <option value={1}>Baja</option>
                                <option value={2}>Media</option>
                                <option value={3}>Alta</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="space-y-4 pt-8">
                    {isAllowed(
                        "OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_FECHA"
                    ) && (
                        <div className="inline-flex items-center w-full">
                            <label
                                htmlFor="FechaOrdenCompra"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Fecha:
                            </label>
                            <input
                                type="date"
                                className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                                name="FechaOrdenCompra"
                                value={Moment(data.FechaOrdenCompra).format(
                                    "yyyy-MM-DD"
                                )}
                                onChange={handleChange}
                            />
                        </div>
                    )}
                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="FechaEntrega"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha entr:
                        </label>
                        <input
                            type="date"
                            name="FechaEntrega"
                            value={Moment(data.FechaEntrega).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                        />
                    </div>
                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="FechaVencimiento"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha vto.:
                        </label>
                        <input
                            type="date"
                            name="FechaVencimiento"
                            value={Moment(data.FechaVencimiento).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                        />
                    </div>
                    {data.Numero !== "" && (
                        <p className="block text-xs  font-semibold text-gray-900">
                            Número:
                            <span className="ml-4 font-semibold text-gray-700">
                                {data.Numero && data.Numero}
                            </span>
                        </p>
                    )}
                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="NumeroEntidad"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Número OC cliente:{" "}
                        </label>
                        <input
                            type="text"
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                            name="NumeroEntidad"
                            value={data.NumeroEntidad || ""}
                            onChange={handleChange}
                        />
                    </div>
                    {isAllowed(
                        "OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_DEPOSITOENTREGA"
                    ) && (
                        <div className="inline-flex items-center w-full">
                            <label
                                htmlFor="depositoentrega"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Deposito entrega:
                            </label>
                            <div className="ml-4 text-gray-700 text-xs">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"deposito"}
                                            view={"single"}
                                            title={"Seleccione depósito"}
                                            id={
                                                data.DepositoEntregaKey !==
                                                    null &&
                                                data.DepositoEntregaKey &&
                                                data.DepositoEntregaKey
                                            }
                                        />
                                    }
                                    textButton={`${
                                        data.DepositoEntregaCodigo === null
                                            ? "Sin seleccion"
                                            : data.DepositoEntregaCodigo &&
                                              JSON.stringify(
                                                  data.DepositoEntregaCodigo
                                              )
                                    }`}
                                    ruta={ruta}
                                    action={"/to_assign_delivery_deposit/"}
                                />
                            </div>
                        </div>
                    )}
                    <div className="inline-flex items-center w-full">
                        <label
                            htmlFor="tipoentrega"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Tipo de entrega:
                        </label>
                        <div className="ml-4 text-gray-700 text-xs">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"tipoentrega"}
                                        view={"single"}
                                        title={"Seleccione tipo de entrega"}
                                        id={
                                            data.TipoEntrega !== null &&
                                            data.TipoEntrega &&
                                            data.TipoEntrega.Nombre
                                        }
                                    />
                                }
                                textButton={`${
                                    data.TipoEntrega === null
                                        ? "Sin seleccion"
                                        : data.TipoEntrega &&
                                          JSON.stringify(
                                              data.TipoEntrega.Nombre
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_delivery_type/"}
                            />
                        </div>
                    </div>
                    {isAllowed(
                        "OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_SECTOR"
                    ) && (
                        <div className="w-full inline-flex items-center">
                            <label
                                htmlFor="email"
                                className="block text-xs  font-semibold text-gray-900"
                            >
                                Sector:
                            </label>
                            <div className="ml-4">
                                <Selector
                                    content={
                                        <SelectorGenerico
                                            ruta={"sector"}
                                            view={"single"}
                                            title={"Seleccione sector"}
                                            id={
                                                data.Sector !== null &&
                                                data.Sector &&
                                                data.Sector.SectorKey
                                            }
                                        />
                                    }
                                    textButton={`${
                                        data.Sector === null
                                            ? "Sin seleccion"
                                            : data.Sector &&
                                              JSON.stringify(data.Sector.Nombre)
                                    }`}
                                    ruta={ruta}
                                    action={"/to_assign_sector/"}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
