import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Moment from "moment";
import { generarSignature } from "../../../../hooks/useSignatureOperation"
import UserContext from "../../../../context/UserContext";
import AlertContext from "../../../../context/AlertContext";

export const ModalOptionsVenta = ({
    action,
    actionText,
    item,
    id,
    requestEndpoint = "",
    requestAction,
    requestSvg,
}) => {
    const cancelButtonRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState([]);
    const [historialEstados, setHistorialEstados] = useState([]);
    const [newValue, setNewValue] = useState(null);

    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const { url, headers, user, workstationActive } = useContext(UserContext);

    const initialRequest = () => {
        switch (requestAction) {
            case "previewPDF":
                verPDF();
                break;

            case "cambio_estado":
                handleRequestData(item.Estado.EstadoKey);
                break;

            default:
                break;
        }
    };

    const handleRequestData = async (auxParam) => {
        setOpen(true);
        setLoading(true);
        await axios
            .post(url + requestEndpoint, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
            })
            .then(function (res) {
                console.log(res);
                setRequestData(res.data);
                setLoading(false);
                if(auxParam !== null || auxParam !== undefined) {
                    setNewValue(auxParam)
                }
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    //funcion para obtener el PDF en una ventana nueva
    const verPDF = async () => {
        await axios
            .post(
                url + requestEndpoint,
                {
                    Usuario: user.UsuarioKey,
                    Workstation: workstationActive.WorkstationKey,
                    Llave: generarSignature(id),
                },
                { responseType: "arraybuffer" }
            )
            .then(function (response) {
                // console.log(response);
                const file = new Blob([response.data], {
                    type: "application/pdf",
                });
                // Construir la URL del Blob
                const fileURL = URL.createObjectURL(file);
                // console.log(fileURL);
                // Abrir el PDF en una nueva ventana o pestaña
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    const handleChangeRequest = async () => { 
        console.log(url + action + constructUrl(newValue));
        await axios.post(url + action + constructUrl(newValue), {
            Workstation: workstationActive.WorkstationKey,
            Usuario: user.UsuarioKey,
        }).then(function(res){
            if(res.status === 200){
                setOpen(false);
                setResponseText('Se modificó la orden exitósamente');
                setStatusCode(200);
            }
        }).catch(function(error){
            console.log(error.response);
            if (error.response != undefined) {
                setOpen(false);
                if (error.response.status === 404) {
                    setResponseText(
                        "Error de petición. Consulte con administración"
                    );
                } else {
                    setResponseText(error.response.data.Motivo);
                }
                setStatusCode(error.response.status);
            } else {
                setOpen(false);
                setResponseText(
                    "Hubo un error crítico. Consulte con administración"
                );
            }
        });
    };

    const constructUrl = (param) => {
        switch (requestAction) {
            case "cambio_estado":
                return `${param}/${id}`;

            default:
                return undefined
        }
    }

    //SVG para cada opcion en base al param que llegue
    const svgElection = () => {
        switch (requestSvg) {
            case "pdf":
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3"
                        viewBox="0 0 32 32"
                    >
                        <path
                            fill="#909090"
                            d="m24.1 2.072l5.564 5.8v22.056H8.879V30h20.856V7.945z"
                        ></path>
                        <path
                            fill="#f4f4f4"
                            d="M24.031 2H8.808v27.928h20.856V7.873z"
                        ></path>
                        <path
                            fill="#7a7b7c"
                            d="M8.655 3.5h-6.39v6.827h20.1V3.5z"
                        ></path>
                        <path
                            fill="#dd2025"
                            d="M22.472 10.211H2.395V3.379h20.077z"
                        ></path>
                        <path
                            fill="#464648"
                            d="M9.052 4.534H7.745v4.8h1.028V7.715L9 7.728a2.042 2.042 0 0 0 .647-.117a1.427 1.427 0 0 0 .493-.291a1.224 1.224 0 0 0 .335-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.409-.104a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.193a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.671-2.306c-.111 0-.219.008-.295.011L12 4.538h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.392h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.08-3.915H15v4.8h1.028V7.434h1.3v-.892h-1.3V5.43h1.4v-.892"
                        ></path>
                        <path
                            fill="#dd2025"
                            d="M21.781 20.255s3.188-.578 3.188.511s-1.975.646-3.188-.511m-2.357.083a7.543 7.543 0 0 0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14.216 14.216 0 0 0 1.658 2.252a13.033 13.033 0 0 0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.787 10.787 0 0 1-.517 2.434a4.426 4.426 0 0 1-.547-2.162Zm-4.649 10.516c-.978-.585 2.051-2.386 2.6-2.444c-.003.001-1.576 3.056-2.6 2.444M25.9 20.895c-.01-.1-.1-1.207-2.07-1.16a14.228 14.228 0 0 0-2.453.173a12.542 12.542 0 0 1-2.012-2.655a11.76 11.76 0 0 0 .623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933 2.013a9.309 9.309 0 0 0 .665 2.338s-.425 1.323-.987 2.639s-.946 2.006-.946 2.006a9.622 9.622 0 0 0-2.725 1.4c-.824.767-1.159 1.356-.725 1.945c.374.508 1.683.623 2.853-.91a22.549 22.549 0 0 0 1.7-2.492s1.784-.489 2.339-.623s1.226-.24 1.226-.24s1.629 1.639 3.2 1.581s1.495-.939 1.485-1.035"
                        ></path>
                        <path
                            fill="#909090"
                            d="M23.954 2.077V7.95h5.633z"
                        ></path>
                        <path fill="#f4f4f4" d="M24.031 2v5.873h5.633z"></path>
                        <path
                            fill="#fff"
                            d="M8.975 4.457H7.668v4.8H8.7V7.639l.228.013a2.042 2.042 0 0 0 .647-.117a1.428 1.428 0 0 0 .493-.291a1.224 1.224 0 0 0 .332-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.411-.105a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.194a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.67-2.306c-.111 0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.315h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.077-3.915h-2.43v4.8h1.028V7.357h1.3v-.892h-1.3V5.353h1.4v-.892"
                        ></path>
                    </svg>
                );
            case "change":
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                        />
                    </svg>
                );
            
        }
    };

    return (
        <>
            <button
                className="flex items-center font-medium text-left px-4 py-2 text-sm text-gray-700 hover:underline"
                onClick={initialRequest}
            >
                {svgElection()} {actionText}
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto w-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-auto sm:p-6">
                                    {(() => {
                                        switch (requestAction) {
                                            case "cambio_estado":
                                                return (
                                                    <div
                                                        className={`${
                                                            loading
                                                                ? "opacity-50"
                                                                : "opacity-100"
                                                        }`}
                                                    >
                                                        <div>
                                                            <label className="text-base font-medium text-gray-900">
                                                                Estados
                                                                disponibles
                                                            </label>
                                                            <p className="text-sm leading-5 text-gray-500">
                                                                Elija el estado
                                                                que quiera
                                                                cambiar la
                                                                operación
                                                            </p>
                                                            <fieldset className="mt-4">
                                                                <legend className="sr-only">
                                                                    State change
                                                                    method
                                                                </legend>
                                                                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                                    {requestData.map(
                                                                        (
                                                                            est,
                                                                            estIdx
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    estIdx
                                                                                }
                                                                                className="flex items-center"
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    checked={newValue == est.id}
                                                                                    value={est.id}
                                                                                    onChange={(e) => setNewValue(e.target.value)}
                                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                                />
                                                                                <label
                                                                                    htmlFor={
                                                                                        est.id
                                                                                    }
                                                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                                                >
                                                                                    {
                                                                                        est.Nombre
                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                );
                                            default:
                                                return null;
                                        }
                                    })()}

                                    <div
                                        className={`mt-5 sm:mt-4 sm:flex sm:flex-row-reverse ${
                                            loading
                                                ? "opacity-50"
                                                : "opacity-100"
                                        }`}
                                    >
                                        <button
                                            type="button"
                                            className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm bg-blue-600 hover:bg-blue-800 ${
                                                loading
                                                    ? "cursor-not-allowed"
                                                    : "cursor-pointer"
                                            }`}
                                            onClick={handleChangeRequest}
                                        >
                                            Aceptar
                                        </button>
                                        <button
                                            type="button"
                                            className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm ${
                                                loading
                                                    ? "cursor-not-allowed"
                                                    : "cursor-pointer"
                                            }`}
                                            onClick={() => setOpen(!open)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
