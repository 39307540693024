import { useContext, useEffect, useState } from "react";
import FilterContext from "../../context/FilterContext";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const FilterByRadioButtonGeneric = ({
    source = [],
    sourceIdField,
    sourceDescField,
    tipoFiltro,
    title,
    viewField
}) => {
    const [checked, setChecked] = useState("");
    const { dispatchFilterReducer } = useContext(FilterContext);
    const [selectedValue, setSelectedValue] = useState(null);
    const [enabled, setEnabled] = useState(false);

    const handleChange = ({ target }) => {
        const { value, id } = target;

        setSelectedValue(id);

        if (value === checked) {
            setChecked("");
        } else {
            setChecked(value);
        }
    };

    useEffect(() => {
        if (selectedValue) {

            const action = {
                type:'addOrUpdateFilter',
                payload:{filterName:'byRadioButton_'+viewField, filterType:tipoFiltro, filterField:viewField, value:selectedValue},
            };

            dispatchFilterReducer(action); 
        }
    }, [selectedValue]);

    const unselectRadio = (idRadioButton) => {
        document.getElementById(`${idRadioButton}`).checked = false;
        setChecked("");

        const action = {
            type:'deleteFilter',
            payload:{filterName:'byRadioButton_'+viewField},
        };

        dispatchFilterReducer(action); 
    };


    return (
        <div className="mt-4">
            <Switch.Group
                as="div"
                className="flex items-center justify-between"
            >
                <span className="flex flex-grow flex-col">
                    <Switch.Label
                        as="span"
                        className="text-sm font-medium leading-6 text-gray-900"
                        passive
                    >
                        {title}
                    </Switch.Label>
                    <Switch.Description
                        as="span"
                        className="text-sm text-gray-500"
                    >
                        Ale gay
                    </Switch.Description>
                </span>
                <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                        enabled ? "bg-indigo-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                >
                    <span
                        aria-hidden="true"
                        className={classNames(
                            enabled ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                    />
                </Switch>
            </Switch.Group>
            {/* <label className="text-sm font-medium text-gray-900">{title}</label> */}

            {/* <fieldset className="mt-4">
                {/* <legend className="sr-only">Estado operaciones</legend> 
                <div className="space-y-4">
                    <input type="radio" name="" id="" />
                    {source.length > 0 && source.map((s) => (
                        <div key={s.id} className="flex items-center">
                            <input
                                id={s[sourceIdField]}
                                name={s[sourceDescField]}
                                type="radio"
                                value={s[sourceDescField]}
                                onChange={handleChange}
                                checked={checked === s[sourceDescField] ? true : false}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 radiosButton"
                            />
                            <label
                                htmlFor={s[sourceIdField]}
                                className="ml-3 block text-sm font-medium text-gray-700"
                            >
                                {s[sourceDescField]}
                            </label>
                            <button
                                className={`ml-3 text-xs text-red-600 ${
                                    checked === s[sourceDescField]
                                        ? "opacity-100"
                                        : "opacity-0"
                                }`}
                                id="radioDeleteButton"
                                onClick={() => unselectRadio(s[sourceIdField])}
                            >
                                X
                            </button>
                        </div>
                    ))}
                </div>
            </fieldset> */}
        </div>
    );
};
