import { useEffect, useState, useContext } from "react";
import UserContext from "../../../context/UserContext";
import axios from "axios";
import ModalContext from "../../../context/ModalContext";

export const useSelectorMenuTree = (ruta, filter, id) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState(false);
    const [busqueda, setBusqueda] = useState("");
    const [objSelected, setObjSelected] = useState(null);

    const { user, headers, url, workstationActive } = useContext(UserContext);
    const { select, setSelect } = useContext(ModalContext);

    const [allColumns, setAllColumns] = useState([]);
    
    const filterResult = data.filter((item) =>
        item.Filtered.toLowerCase().includes(busqueda.toLowerCase())
    );

    const getData = async () => {
        setLoading(true);
        await axios
            .post(url + ruta + "/readall", {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                RequiereContexto: true
            })
            .then(function (response) {
                console.log(response.data);
                setLoading(false);
                setAllColumns(response.data.WebForms);
                setData(response.data.Data);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    useEffect(() => {
        setAllColumns([]);
    }, [ruta]);

    useEffect(() => {
        getData();
        if (id === false) {
            setSelect([]);
        }
        if (select !== null) {
            if (id === parseInt(select)) {
                setSelect(id);
            } else if (select.length === 0) {
                setSelect(id);
            } else {
                setSelect(id);
            }
        }
    }, [ruta, id]);

    useEffect(() => {
        // console.log("cambios en el table format");
    }, [allColumns]);

    const aplyChanges = (e) => {
        setAllColumns([...e]);
        // console.log(allColumns);
    };

    const saveChanges = (data) => {
        // console.log("guardando cambios...");

        var dataColumn = data.map((item) => {
            var newData = {
                UsuarioKey: user.UsuarioKey,
                WebFormDefaultKey: item.WebFormDefaultKey,
                FieldWidth: item.FieldWidth,
                Visible: item.Visible,
                Orden: item.Orden,
            };
            return newData;
        });

        var postData = {
            Ruta: "proveedor",
            UsuarioKey: user.UsuarioKey,
            Data: dataColumn,
        };

        axios
            .post(url + "webformusuario/editruta", postData, {
                headers: headers,
            })
            .then(function (response) {
                setMessageAlert(true);
                setTimeout(() => {
                    setMessageAlert(false);
                }, 500);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const defaultSetting = (e) => {
        setAllColumns([...e]);
        setMessageAlert(true);
        setTimeout(() => {
            setMessageAlert(false);
        }, 500);
    };

    return {
        data,
        loading,
        allColumns,
        messageAlert,
        objSelected,
        setObjSelected,
        aplyChanges,
        saveChanges,
        defaultSetting,
        busqueda,
        setBusqueda,
        filterResult,
    };
};
