import { useContext, useState, useEffect } from "react";
import UserContext from "../../../context/UserContext";
import axios from "axios";
import ModalContext from "../../../context/ModalContext";

export const useSelectorByMoneda = (ruta, action, onHandleChange) => {
    const [monedas, setMonedas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState(false);
    const [search, setSearch] = useState("");

    const [open, setOpen] = useState(false);
    const { url, user, headers, workstationActive } = useContext(UserContext);
    const [columns, setColumns] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });

    const { select, data } = useContext(ModalContext);

    const getMoneda = async () => {
        setOpen(true);
        setLoading(true);
        await axios
            .post(url + "moneda/readall", {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                RequiereContexto: true,
            })
            .then(function (response) {
                console.log(response);
                setLoading(false);
                setMonedas(response.data.Data);
                setColumns(response.data.WebForms);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    const selectMoneda = () => {};

    const handleRequestMoneda = async (param) => {
        await axios
            .post(url + ruta + action + param, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                [ruta]: data,
                // RequiereContexto: true,
            })
            .then(function (response) {
                console.log(response);
                onHandleChange(response.data);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    return {
        open,
        data,
        monedas,
        search,
        loading,
        select,
        columns,
        messageAlert,
        selectMoneda,
        getMoneda,
        setSearch,
        setOpen,
        sortConfig,
        setSortConfig,
        handleRequestMoneda,
    };
};
