//react
import axios from "axios";
import { useState, useEffect } from "react";

//components

import { SelectorArticulo } from "../../../Selector/SelectorByListaPrecio/SelectorArticulo";

//assets
import { useContext } from "react";
import UserContext from "../../../../context/UserContext";
import ModalContext from "../../../../context/ModalContext";
import { ArticulosItem } from "./ArticulosItem";
import { useActions } from "../../../../permissions/useActions";


export const SecondComponent = ({ nextStep, prevStep }) => {
    const { url, headers, user, workstationActive } = useContext(UserContext);
    const { data, setData, acciones } = useContext(ModalContext);
    const { isAllowed } = useActions(acciones);
    var cantidad = 1;
    const [items, setItems] = useState([]);

    var listaPrecioKey =
        data.Operacion && data.Operacion.ListaPrecios == null
            ? 1
            : data.Operacion.ListaPrecios.ListaPreciosKey; ;

    const handleAddArticle = async (e) => {
        await axios
            .post(url + `venta/add_article/${e}/${cantidad}`, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                venta: data,
            })
            .then(function (response) {
                // console.log(response);
                setData(response.data);
                setItems(response.data.Items);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    useEffect(() => {
        setItems(data.Items);
    }, [data]);

    return (
        <>
            <div className="flex items-center justify-between mt-4 mx-10">
                <button
                    className={` 
                            bg-indigo-600
                            text-white
                            hover:bg-indigo-900
                            px-2
                            py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer
                            `}
                    onClick={prevStep}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2em"
                        height="2em"
                        viewBox="0 0 24 24"
                        className="rotate-180"
                    >
                        <path
                            fill="currentColor"
                            d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                        ></path>
                    </svg>
                </button>

                <button
                    className={` 
                            bg-indigo-600
                            text-white
                            hover:bg-indigo-900
                            px-2
                            py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer
                            `}
                    onClick={nextStep}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2em"
                        height="2em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                        ></path>
                    </svg>
                </button>
            </div>
            <div className="mt-3">
                {/* {isAllowed(
                    "OPERACION_ORDEN_COMPRA_CLIENTE_AGREGAR_ARTICULOGENERICO"
                ) && (
                    )} */}
                <SelectorArticulo
                    onAddArticle={handleAddArticle}
                    listaPrecioKey={listaPrecioKey}
                />
            </div>
            <div className="mt-4 flex flex-col border h-60">
                <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-300">
                                    <tr className="border">
                                        <th
                                            scope="col"
                                            className="py-1 pl-4 pr-3 w-0 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            #
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-1 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Código
                                        </th>

                                        <th
                                            scope="col"
                                            className="px-3 py-1 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Artículo
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-1 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Cantidad
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-1 px-3 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            P. Unitario
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-1 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Dto.
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-1 px-3 w-0 text-left text-sm font-semibold text-gray-900 sm:pr-3"
                                        >
                                            P. Total
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative w-0 py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white ">
                                    {items.map((item, itemIdx) => (
                                        <ArticulosItem
                                            key={itemIdx}
                                            item={item}
                                            itemIdx={itemIdx}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
